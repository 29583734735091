import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPrint,
  faChartBar,
  faEye,
  faComputer,
  faHeart,
  faExclamationTriangle,
  faFile,
  faMessage,
  faUser,
  faSignOutAlt,
  faBriefcase,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

SideMenu.propTypes = {};

function SideMenu(props) {
  const listStyle = {
    justifyContent: "normal",
  };
  return (
    <div className="widget-filter st2  style-scroll po-sticky">
         <div className="cv-form-details stc po-sticky job-sg">
      <ul className="list-infor">
        <li style={listStyle}>
          <FontAwesomeIcon icon={faChartBar} />
          <Link to="/jobseeker/dashboard" className="tag-head">
            Dashboard
          </Link>
        </li>
        <li style={listStyle}>
          <FontAwesomeIcon icon={faEdit} />
          <Link to="/jobseeker/my-profile" className="tag-head">
            Edit Profile
          </Link>
        </li>
        <li style={listStyle}>
          <FontAwesomeIcon icon={faPrint} />
          <Link to="/jobseeker/resume" className="tag-head">
            Print Resume
          </Link>
        </li>
        <li style={listStyle}>
          <FontAwesomeIcon icon={faEye} />
          <Link to="/jobseeker/view-public-profile" className="tag-head">
            View Public Profile
          </Link>
        </li>
        <li style={listStyle}>
          <FontAwesomeIcon icon={faComputer} />
          <Link to="/jobseeker/my-job-application" className="tag-head">
            My Job Applications
          </Link>
        </li>
        <li style={listStyle}>
          <FontAwesomeIcon icon={faHeart} />
          <Link to="/jobseeker/my-favourite-jobs" className="tag-head">
            My Favourite Jobs
          </Link>
        </li>
        <li style={listStyle}>
          <FontAwesomeIcon icon={faBriefcase} />
          <Link to="/jobseeker/my-alerts" className="tag-head">
            My Job Alerts
          </Link>
        </li>
        <li style={listStyle}>
          <FontAwesomeIcon icon={faFile} />
          <Link to="#" className="tag-head">
            Manage Resume
          </Link>
        </li>
        <li style={listStyle}>
          <FontAwesomeIcon icon={faMessage} />
          <Link to="#" className="tag-head">
            My Messages
          </Link>
        </li>
        <li style={listStyle}>
          <FontAwesomeIcon icon={faUser} />
          <Link to="/jobseeker/my-followings" className="tag-head">
            My Followings
          </Link>
        </li>
       
      </ul>
    </div>
    </div>
  );
}

export default SideMenu;
