import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  Button,
  FormLabel,
  Container,
  Tabs,
  Tab,
  Nav,
} from "react-bootstrap";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Loadergif from "../loader/loadergif";

import { Link, useNavigate, useLocation } from "react-router-dom";
//import Toast from "../snakbar/snakbar";

const JOBPOSTING_URL = "jobs/create_job/";
const STATICEDUCATION_URL = "/data/education/";
const STATICLOCATION_URL = "/data/location/";
const STATICJOBTYPE_URL = "/data/job_type/";
const STATICSKILL_URL = "/data/skill_suggestions/";
const STATICEXPERIENCE_URL = "/data/experience/";
const STATICTITLE_URL = "/data/job_title_suggestions/";
const STATICJOBFUNCTION_URL = "/data/job_function/";
const STATICINDUSTRY_URL = "/data/industry/";
const JobDescriptiongeneration_URL = "jobs/generate_job_description";

const NewJob = () => {
  const navigate = useNavigate();
  const [showLocationInput, setShowLocationInput] = useState(true); // Set the initial value based on your requirements

  const [getEducation, setGetEducation] = useState([]);
  const [getJobFunction, setGetJobFunction] = useState([]);
  const [getIndustry, setGetIndustry] = useState([]);
  const [msg, setMsg] = useState("");
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);

  const [getLocation, setGetLocation] = useState([]);
  const [getjobType, setGetJobType] = useState([]);
  const [getExperience, setGetExperience] = useState([]);
  const [skillname, setSkillname] = useState([]);
  const [titlename, setTitlename] = useState([]);
  const [aigeneration, setAiGeneration] = useState([]);

  const [isDraft, setIsDraft] = useState(false);
  const [draftData, setDraftData] = useState(null);
  const [showDraftModal, setShowDraftModal] = useState(false);
  // console.log("isDraft", isDraft);

  const access = localStorage.getItem("access");

  const initialValues = {
    title: "",
    job_code: "",
    location_type: "",
    location: "",
    job_type: "",
    education: "",
    experience: "",
    description: "",
    requirements: "",
    responsibilities: "",
    benefits: "",
    skills: [],
    application_deadline: null,
    vacancies: "",
    min_salary: "",
    max_salary: "",
    company_industry: "",
    job_function: "",
    status: "draft",
  };
  const onSubmit = async (values) => {
    try {
      const selectedTitle = titlename.find(
        (titleoption) => titleoption.title === values.title
      );
      const titleId = selectedTitle ? selectedTitle.id : null;

      const selectedJobType = getjobType.find(
        (jobtypeOption) => jobtypeOption.name === values.job_type
      );
      const jobTypeId = selectedJobType ? selectedJobType.id : null;

      const selectLocationType = getLocation.find(
        (locationOption) => locationOption.name === values.location_type
      );
      const LocationTypeId = selectLocationType ? selectLocationType.id : null;

      const selectedSkills = values.skills.map((selectedSkill) => {
        const skillObject = skillname.find(
          (skilloption) => skilloption.name === selectedSkill
        );
        return skillObject ? skillObject.id : null;
      });

      const selectedIndustry = getIndustry.find(
        (option) => option.name === values.company_industry
      );
      const selectedJobFunction = getJobFunction.find(
        (option) => option.name === values.job_function
      );

      const response = await axios.post(
        JOBPOSTING_URL,

        {
          status: values.status,
          title: titleId,
          job_code: values.job_code,
          location_type: LocationTypeId,
          location: values.location,
          job_type: jobTypeId,
          education: values.education,
          experience: values.experience,
          description: values.description,
          requirements: values.requirements,
          responsibilities: values.responsibilities,
          benefits: values.benefits,
          company_industry: selectedIndustry ? selectedIndustry.id : null,
          job_function: selectedJobFunction ? selectedJobFunction.id : null,
          skills: selectedSkills,
          application_deadline: values.application_deadline,
          vacancies: values.vacancies,
          min_salary: values.min_salary,
          max_salary: values.max_salary,
        },
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === 201) {
        //show()
        navigate("/recruiterprofile2");
      }
      console.log(response.data["message"]);
      setMsg(response.data["message"]);
      setError(response.data.error);
      console.log("error", response.data.error);
      //console.log("check",response.data);
      if (isDraft) {
        setDraftData(values);
        setShowDraftModal(true);
      } else {
        console.log("Form published:", values);
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("title is required"),
    location_type: Yup.string().required("Location Type is required"),
    vacancies: Yup.string().required("vacancies is required"),
    min_salary: Yup.string().required("Min salary is required"),
    max_salary: Yup.string().required("Max salary Type is required"),
    description: Yup.string().required("Description is required"),
    requirements: Yup.string().required("Requirements is required"),
    responsibilities: Yup.string().required("Responsibilities is required"),
    application_deadline: Yup.date().required(
      "Application deadline is required"
    ),
    skills: Yup.array()
      .required("Skills is required")
      .min(1, "At least one skill is required"),
      location: Yup.string().required("Location is required"),
      job_type: Yup.string().required("Employment type is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(STATICEDUCATION_URL, {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        });
        setGetEducation(response.data);
      } catch (error) {
        console.error("Error fetching static data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(STATICLOCATION_URL, {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        });
        setGetLocation(response.data);
        // console.log("Location Type:", formik.values.location_type);
      } catch (error) {
        console.error("Error fetching static data:", error);
      }
    };

    fetchData();
  }, [access, formik.values.location_type]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(STATICJOBTYPE_URL, {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        });
        setGetJobType(response.data);
      } catch (error) {
        console.error("Error fetching static data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(STATICJOBFUNCTION_URL, {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        });
        setGetJobFunction(response.data);
      } catch (error) {
        console.error("Error fetching static data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(STATICINDUSTRY_URL, {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        });
        setGetIndustry(response.data);
      } catch (error) {
        console.error("Error fetching static data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(STATICEXPERIENCE_URL, {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        });
        setGetExperience(response.data);
      } catch (error) {
        console.error("Error fetching static data:", error);
      }
    };

    fetchData();
  }, []);

  const fetchSkills = async (query) => {
    try {
      const response = await axios.get(STATICSKILL_URL, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json",
        },
        params: { query },
      });

      const data = response.data;
      setSkillname(data);
      // console.log();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchSkills(formik.values.skills);
  }, [formik.values.skills]);

  const fetchTitle = async (query) => {
    try {
      const response = await axios.get(STATICTITLE_URL, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json",
        },
        params: { query },
      });

      const data = response.data;
      setTitlename(data);
      // console.log();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTitle(formik.values.title);
  }, [formik.values.title]);

  const AIgenerate = async (formik) => {
    // console.log("access", access);

    if (formik.values.title) {
      // console.log("ai generated", formik);

      try {
        setLoading(true);
        setAiGeneration([]);
        const response = await axios.get(
          `/jobs/generate_job_description?job_title=${formik.values.title}`,
          {
            headers: {
              Authorization: `Bearer ${access}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = response.data.payload;
        const responsibilities = data.responsibilities;
        let res = responsibilities.join("\n");
        let res_text = res.toString();
        const responsibilitiesDelta = convertPlainTextToDelta(res_text);
        //  console.log("responsibilitiesDelta", responsibilitiesDelta);

        const requirements = data.requirements;
        let req_join = requirements.join("\n");
        let req_text = req_join.toString();
        const requirementsDelta = convertPlainTextToDelta(req_text);
        // console.log("requirementsDelta", requirementsDelta);
        // console.log("req_textres_text", req_text);

        setAiGeneration({
          description: data.description,
          responsibilities: responsibilitiesDelta,
          requirements: requirementsDelta,
        });
        setLoading(false);
        // console.log("ai generated data", data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    } else {
      formik.handleSubmit();
    }
  };
  /*  const toast = useRef(null);
  const show = () => {
    toast.current.show({ severity: "success", summary: 'Success', detail: 'job posted successfully',life: 3000 });
  }; */

  const convertPlainTextToDelta = (plainText) => {
    return {
      ops: [
        {
          insert: plainText,
        },
      ],
    };
  };
  const backtoDashboard = () => {
    navigate("/recruiterprofile2");
  };
  return (
    <>
      {/*  <Toast ref={toast} /> */}
      {loading ? <Loadergif /> : ""}

      <section
        className={
          loading
            ? "blog-details-section custom_opacity"
            : "blog-details-section "
        }
      >
        <div className="tf-container">
          <Button
            style={{ marginBottom: "13px" }}
            variant="outline-success"
            onClick={backtoDashboard}
          >
            Back to dashboard
          </Button>{" "}
          <div className="row">
            <div className="blog style-1 stc col-lg-9 col-md-12">
              <div className="blog-detail blog-detail-side-bar-content side-bar-blog">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {(formik) => (
                    <form
                      onSubmit={formik.handleSubmit}
                      className="wd-form-rating"
                    >
                      <div className="row">
                        <div className="nav-links stc">
                          <div className="form-rating-heading">
                            <p>Job title and Department details </p>
                          </div>
                        </div>
                        <Col xs={12} md={12}>
                          <div className="wrap-input">
                            <label htmlFor="title">
                              {" "}
                              <span>*</span>job title
                            </label>
                            <input
                              type="text"
                              list="titleList"
                              name="title"
                              id="title"
                              onChange={(e) => {
                                formik.handleChange(e);

                                fetchTitle(e.target.value);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.title}
                            />
                            <datalist id="titleList">
                              {titlename.map((titleoption) => (
                                <option
                                  key={titleoption.id}
                                  value={titleoption.title}
                                  id={titleoption.id}
                                />
                              ))}
                            </datalist>
                            {formik.touched.title && formik.errors.title && (
                              <div style={{ color: "red" }}>
                                {formik.errors.title}
                              </div>
                            )}
                            {error && (
                              <div
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {error.title && <p>{error.title[0]}</p>}
                                                            
                              </div>
                            )}
                          </div>
                        </Col>

                        <Col xs={6} md={6}>
                          <div className="wrap-input">
                            <label htmlFor="job_code">job code</label>
                            <input
                              type="text"
                              id="job_code"
                              name="job_code"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.job_code}
                            />
                             {error && (
                              <div
                                className="error-message"
                                style={{ color: "red" }}
                              >
                                {error.job_code && <p>{error.job_code[0]}</p>}
                                                            
                              </div>
                            )}
                          </div>
                        </Col>
                        <div className="nav-links stc">
                          <div className="form-rating-heading">
                            <p>Location</p>
                          </div>
                        </div>

                        <Col xs={12} md={12}>
                          <div className="wrap-input">
                            <label htmlFor="location_type">
                              <span>*</span>Workplace
                            </label>
                            <Field
                              as="select"
                              id="location_type"
                              name="location_type"
                              onChange={(e) => {
                                formik.handleChange(e);
                                setShowLocationInput(e.target.value);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.location_type}
                            >
                              <option value="" label="Select an option" />
                              {getLocation.map((locationOption) => (
                                <option
                                  key={locationOption.id}
                                  value={locationOption.name}
                                  label={locationOption.name}
                                />
                              ))}
                            </Field>
                            {formik.touched.location_type &&
                              formik.errors.location_type && (
                                <div style={{ color: "red" }}>
                                  {formik.errors.location_type}
                                </div>
                              )}
                          </div>
                        </Col>

                        <Col xs={12} md={12}>
                          <div className="wrap-input">
                            {["Onsite", "Hybrid"].includes(
                              showLocationInput
                            ) && (
                              <label htmlFor="location">
                                <span>*</span>Office location
                              </label>
                            )}
                            {showLocationInput === "Remote" && (
                              <label htmlFor="location">
                                <span>*</span>Employee location
                              </label>
                            )}
                            <input
                              type="text"
                              id="location"
                              name="location"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.location}
                            />
                            {formik.touched.location &&
                              formik.errors.location && (
                                <div style={{ color: "red" }}>
                                  {formik.errors.location}
                                </div>
                              )}
                          </div>
                        </Col>

                        <div className="nav-links stc">
                          <div className="form-rating-heading">
                            <p>Description</p>
                          </div>
                        </div>
                        <Col xs={12} md={9}>
                          <p>
                            <strong>*About the role</strong>
                          </p>
                        </Col>
                        <Col xs={12} md={3}>
                          <div className="ip p-2">
                            <button
                              className={
                                loading ? "d-none" : "tf-btn-submit style-2"
                              }
                              type="button"
                              onClick={() => AIgenerate(formik)}
                            >
                              Generate with AI
                            </button>
                          </div>
                        </Col>
                        {aigeneration && (
                          <>
                            <Col xs={12} md={12}>
                              <div className="ip p-2">
                                <label htmlFor="description">Description</label>

                                <ReactQuill
                                  id="description"
                                  name="description"
                                  value={
                                    formik.values.description ||
                                    aigeneration.description
                                  }
                                  onChange={(value) =>
                                    formik.setFieldValue("description", value)
                                  }
                                  /*  modules={{
                            toolbar: [
                              [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                              ['bold', 'italic', 'underline', 'strike'],
                              [{ 'color': [] }, { 'background': [] }],
                              [{ 'align': [] }],
                              ['link', 'image'],
                              ['clean']
                            ]
                          }}
                          formats={[
                            'header', 'font', 'size',
                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                            'list', 'bullet', 'indent',
                            'link', 'image', 'color', 'align'
                          ]} */
                                />
                                {/*  <Field
                              as="textarea"
                              id="description"
                              name="description"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              
                              value={formik.values.description || aigeneration.description}
                              rows="4" // Set the number of rows for the textarea
                            /> */}
                                {formik.touched.description &&
                                  formik.errors.description && (
                                    <div style={{ color: "red" }}>
                                      {formik.errors.description}
                                    </div>
                                  )}
                              </div>
                            </Col>
                            <Col xs={12} md={12}>
                              <div className="ip p-2">
                                <label htmlFor="requirements">
                                  Requirements
                                </label>

                                <ReactQuill
                                  id="requirements"
                                  name="requirements"
                                  value={
                                    formik.values.requirements ||
                                    aigeneration.requirements
                                  }
                                  onChange={(value) =>
                                    formik.setFieldValue("requirements", value)
                                  }
                                />
                                {/*  <Field
                              as="textarea"
                              id="requirements"
                              name="requirements"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.requirements || aigeneration.requirements}
                              rows="4" // Set the number of rows for the textarea
                            /> */}
                                {formik.touched.requirements &&
                                  formik.errors.requirements && (
                                    <div style={{ color: "red" }}>
                                      {formik.errors.requirements}
                                    </div>
                                  )}
                              </div>
                            </Col>
                            <Col xs={12} md={12}>
                              <div className="ip p-2">
                                <label htmlFor="benefits">Benefits</label>
                                <ReactQuill
                                  id="benefits"
                                  name="benefits"
                                  value={formik.values.benefits}
                                  onChange={(value) =>
                                    formik.setFieldValue("benefits", value)
                                  }
                                />
                                {/*  <Field
                              as="textarea"
                              id="benefits"
                              name="benefits"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.benefits}
                              rows="4" // Set the number of rows for the textarea
                            /> */}
                                {formik.touched.benefits &&
                                  formik.errors.benefits && (
                                    <div style={{ color: "red" }}>
                                      {formik.errors.benefits}
                                    </div>
                                  )}
                              </div>
                            </Col>
                            <Col xs={12} md={12}>
                              <div className="ip p-2">
                                <label htmlFor="responsibilities">
                                  Responsibilities
                                </label>

                                <ReactQuill
                                  id="responsibilities"
                                  name="responsibilities"
                                  value={
                                    formik.values.responsibilities ||
                                    aigeneration.responsibilities
                                  }
                                  onChange={(value) =>
                                    formik.setFieldValue(
                                      "responsibilities",
                                      value
                                    )
                                  }
                                />

                                {/*   <Field
                              as="textarea"
                              id="responsibilities"
                              name="responsibilities"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.responsibilities || aigeneration.responsibilities}
                              rows="4" // Set the number of rows for the textarea
                            /> */}
                                {formik.touched.responsibilities &&
                                  formik.errors.responsibilities && (
                                    <div style={{ color: "red" }}>
                                      {formik.errors.responsibilities}
                                    </div>
                                  )}
                              </div>
                            </Col>
                          </>
                        )}
                        <div className="nav-links stc">
                          <div className="form-rating-heading">
                            <p>Company industry and Job function</p>
                          </div>
                        </div>
                        <Col xs={12} md={6}>
                          <div className="wrap-input">
                            <label htmlFor="company_industry">
                              Company industry
                            </label>
                            <Field
                              as="select"
                              id="company_industry"
                              name="company_industry"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.company_industry}
                            >
                              <option value="" label="Select an option" />
                              {getIndustry.map((IndustryOption) => (
                                <option
                                  key={IndustryOption.id}
                                  value={IndustryOption.name}
                                  label={IndustryOption.name}
                                />
                              ))}
                            </Field>
                            {formik.touched.company_industry &&
                              formik.errors.company_industry && (
                                <div style={{ color: "red" }}>
                                  {formik.errors.company_industry}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col xs={12} md={6}>
                          <div className="wrap-input">
                            <label htmlFor="job_function">Job function</label>
                            <Field
                              as="select"
                              id="job_function"
                              name="job_function"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.job_function}
                            >
                              <option value="" label="Select an option" />
                              {getJobFunction.map((JobFunctionOption) => (
                                <option
                                  key={JobFunctionOption.id}
                                  value={JobFunctionOption.name}
                                  label={JobFunctionOption.name}
                                />
                              ))}
                            </Field>
                            {formik.touched.job_function &&
                              formik.errors.job_function && (
                                <div style={{ color: "red" }}>
                                  {formik.errors.job_function}
                                </div>
                              )}
                          </div>
                        </Col>
                        <div className="nav-links stc">
                          <div className="form-rating-heading">
                            <p>Employment details</p>
                          </div>
                        </div>
                        <Col xs={12} md={3}>
                          <div className="wrap-input">
                            <label htmlFor="job_type"> Employment type</label>
                            <Field
                              as="select"
                              id="job_type"
                              name="job_type"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.job_type}
                            >
                              <option value="" label="Select an option" />
                              {getjobType.map((jobtypeOption) => (
                                <option
                                  key={jobtypeOption.id}
                                  value={jobtypeOption.name}
                                  label={jobtypeOption.name}
                                />
                              ))}
                            </Field>
                            {formik.touched.job_type &&
                              formik.errors.job_type && (
                                <div style={{ color: "red" }}>
                                  {formik.errors.job_type}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col xs={12} md={3}>
                          <div className="wrap-input">
                            <label htmlFor="experience">Experience</label>
                            <Field
                              as="select"
                              id="experience"
                              name="experience"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.experience}
                            >
                              <option value="" label="Select an option" />
                              {getExperience.map((experienceOption) => (
                                <option
                                  key={experienceOption.id}
                                  value={experienceOption.id}
                                  label={experienceOption.level}
                                />
                              ))}
                            </Field>
                            {formik.touched.experience &&
                              formik.errors.experience && (
                                <div style={{ color: "red" }}>
                                  {formik.errors.experience}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col xs={12} md={3}>
                          <div className="wrap-input">
                            <label htmlFor="education">Education</label>
                            <Field
                              as="select"
                              id="education"
                              name="education"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.education}
                            >
                              <option value="" label="Select an option" />
                              {getEducation.map((educationOption) => (
                                <option
                                  key={educationOption.id}
                                  value={educationOption.id}
                                  label={educationOption.level}
                                />
                              ))}
                            </Field>
                            {formik.touched.education &&
                              formik.errors.education && (
                                <div style={{ color: "red" }}>
                                  {formik.errors.education}
                                </div>
                              )}
                          </div>
                        </Col>

                        <Col xs={12} md={3}>
                          <div className="wrap-input">
                            <label htmlFor="">Keywords</label>
                            <Field as="select" id="" name="">
                              <option value="" label="Select an option" />
                              <option value="option1" label="Option 1" />
                              <option value="option2" label="Option 2" />
                              <option value="option3" label="Option 3" />
                            </Field>
                            {/*  {formik.touched.skills && formik.errors.skills && (
                            <div style={{ color: "red" }}>
                              {formik.errors.skills}
                            </div>
                          )} */}
                          </div>
                        </Col>

                        <Col xs={12} md={6}>
                          <div className="wrap-input">
                            <label htmlFor="application_deadline">
                              Application deadline
                            </label>
                            <input
                              type="date"
                              placeholder="EX: (YYYY-MM-DD)"
                              id="application_deadline"
                              name="application_deadline"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.application_deadline}
                            />
                            {formik.touched.application_deadline &&
                              formik.errors.application_deadline && (
                                <div style={{ color: "red" }}>
                                  {formik.errors.application_deadline}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col xs={12} md={6}>
                          <div className="wrap-input">
                            <label htmlFor="vacancies">Vacancies</label>
                            <input
                              type="text"
                              // placeholder="EX: Boston University"
                              id="vacancies"
                              name="vacancies"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.vacancies}
                            />
                            {formik.touched.vacancies &&
                              formik.errors.vacancies && (
                                <div style={{ color: "red" }}>
                                  {formik.errors.vacancies}
                                </div>
                              )}
                          </div>
                        </Col>

                        <Col xs={12} md={6}>
                          <div className="wrap-input">
                            <label htmlFor="skills">Skills</label>
                            <Select
                              isMulti
                              name="skills"
                              options={skillname.map((skilloption) => ({
                                value: skilloption.id,
                                label: skilloption.name,
                              }))}
                              onChange={(selectedOptions) => {
                                // Extract labels from selected options
                                const selectedSkills = selectedOptions.map(
                                  (option) => option.label
                                );

                                // Update Formik values
                                formik.setFieldValue("skills", selectedSkills);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.skills.map(
                                (selectedSkill) => ({
                                  value: selectedSkill,
                                  label: selectedSkill,
                                })
                              )}
                            />
                            {formik.touched.skills && formik.errors.skills && (
                              <div style={{ color: "red" }}>
                                {formik.errors.skills}
                              </div>
                            )}
                          </div>
                        </Col>

                        <div className="nav-links stc">
                          <div className="form-rating-heading">
                            <p>Annual salary</p>
                          </div>
                        </div>
                        <Col xs={12} md={6}>
                          <div className="wrap-input">
                            <label htmlFor="min_salary">Min salary</label>
                            <input
                              type="text"
                              // placeholder="EX: Boston University"
                              id="min_salary"
                              name="min_salary"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.min_salary}
                            />
                            {formik.touched.min_salary &&
                              formik.errors.min_salary && (
                                <div style={{ color: "red" }}>
                                  {formik.errors.min_salary}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col xs={12} md={6}>
                          <div className="wrap-input">
                            <label htmlFor="max_salary">Max salary</label>
                            <input
                              type="text"
                              // placeholder="EX: Boston University"
                              id="max_salary"
                              name="max_salary"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.max_salary}
                            />
                            {formik.touched.max_salary &&
                              formik.errors.max_salary && (
                                <div style={{ color: "red" }}>
                                  {formik.errors.max_salary}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col xs={12} md={6}>
                          <div className="wrap-input">
                            <label htmlFor="status">Job Status</label>
                            <Field
                              as="select"
                              id="status"
                              name="status"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.status}
                            >
                              <option value="draft" label="Draft" />
                              <option value="published" label="Published" />
                              <option value="hold" label="Hold" />
                              <option value="expired" label="Expired" />
                            </Field>
                            {formik.touched.status && formik.errors.status && (
                              <div style={{ color: "red" }}>
                                {formik.errors.status}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col xs={12} md={6}></Col>
                        <Col xs={12} md={2}>
                          <div className="ip p-2">
                            <button
                              className="tf-btn-submit style-2"
                              type="submit"
                              onClick={() => {
                                setIsDraft(false);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </Col>
                        <Col xs={12} md={2}>
                          <div className="ip p-2">
                            <button
                              className="tf-btn-submit style-2"
                              type="button"
                              onClick={() => {
                                setIsDraft(true);
                                formik.handleSubmit();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </Col>
                        <div style={{ color: "red" }}>{msg}</div>
                      </div>
                    </form>
                  )}
                </Formik>
                {showDraftModal && (
                  <DraftModal
                    draftData={draftData}
                    onClose={() => setShowDraftModal(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const DraftModal = ({ draftData, onClose }) => {
  return (
    <Modal show={true} onHide={onClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          Job preview:<strong>{draftData.title}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {draftData && (
          <div>
            <h5 className="centered-green-title">
              <strong>Prismaticsoft</strong>
            </h5>
            <h6 className="centered-title">{draftData.title}</h6>
            <p className="center-model-contend">
              {draftData.location_type}- {draftData.location},{" "}
              {draftData.job_type}
            </p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Tab.Container defaultActiveKey="tab1">
          <Nav variant="tabs" className="nav-tabs">
            <Nav.Item>
              <Nav.Link eventKey="tab1">Overview</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tab2">Application</Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="tab-content-container">
            <Tab.Content>
              <Tab.Pane eventKey="tab1">
                <h6 className="centered-title">Description</h6>

                <p
                  dangerouslySetInnerHTML={{ __html: draftData.description }}
                ></p>
                <h6 className="centered-title">Requirements</h6>
                <p
                  dangerouslySetInnerHTML={{ __html: draftData.requirements }}
                ></p>
                {/* <p>{draftData.requirements}</p> */}
                <h6 className="centered-title">Responsibilities</h6>
                {/*   <p>{draftData.responsibilities}</p> */}
                <p
                  dangerouslySetInnerHTML={{
                    __html: draftData.responsibilities,
                  }}
                ></p>
                <h6 className="centered-title">Benefits</h6>
                {/*  <p>{draftData.benefits}</p> */}
                <p dangerouslySetInnerHTML={{ __html: draftData.benefits }}></p>
              </Tab.Pane>
              <Tab.Pane eventKey="tab2">
                <p>
                  Whether a section in a paper is a paragraph. For instance, in
                  some styles of writing, particularly journalistic styles, a
                  paragraph can be just one sentence long.
                </p>
              </Tab.Pane>
            </Tab.Content>
          </div>
          <div className="apply-button-container">
            <Button variant="success" onClick={onClose}>
              Apply for the job
            </Button>
          </div>
        </Tab.Container>
      </Modal.Footer>
    </Modal>
  );
};

export default NewJob;
