import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ResendVerificationEmail from "./ResendVerificationEmail";
import axios from "../../api/axios";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";


const VERIFICATION_URL = "/recruiter/verification/";

const Verification = () => {
  
  const email = localStorage.getItem("email");
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const queryParams = new URLSearchParams(location.search);
  console.log("queryParams",queryParams)
  const token = queryParams.get("token");
  //console.log("token", token);
  const uid = queryParams.get("uid");
  const currentUrl = window.location.href;
  console.log("currentUrl",currentUrl)
  /*const url = new URL(currentUrl);
  const uid = url.searchParams.get("uid");
  const token = url.searchParams.get("token"); */

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axios.post(VERIFICATION_URL, { uid, token });
        alert("try")
        console.log("uid",uid);
        console.log("token",token);
        console.log("API Response:", response.data);

        if (response.data.status === 200) {
          console.log("Verification Successful");
          setSuccess(true);
          navigate(from, { replace: true });
        } else {
          console.log("Verification Failed");
          setSuccess(false);
        }
      } catch (error) {
        console.log("Error:",error);
        alert("catch")
      }
    };

    verifyToken();
  }, [uid, token]);

  return (
    <div className="container mt-5">
      <div className="card-body">
        {success === false && (
          <>
            <Card className="text-center">
              <Card.Header>
                <strong>Confirm your email address</strong>
              </Card.Header>
              <Card.Body>
                <Card.Title> </Card.Title>
                <Card.Text>
                  An email from Myzonehub has been send to <strong>{email}</strong>.<br />
                  Please follow the instruction in the email to <br />
                  finish setting up your account.
                  <ResendVerificationEmail
                    //onSuccess={() => console.log("Resend successful")}
                   // onError={() => console.log("Resend failed")}
                  />
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
              <Card.Footer className="text-muted">
                made a mistake ? <Link to="/createaccount"> Start again</Link>
              </Card.Footer>
            </Card>
          </>
        )}
      </div>
    </div>
  );
};

export default Verification;
/* return (
  <Card className="text-center">
    <Card.Header>Featured</Card.Header>
    <Card.Body>
      <Card.Title>Special title treatment</Card.Title>
      <Card.Text>
        With supporting text below as a natural lead-in to additional content.
      </Card.Text>
      <Button variant="primary">Go somewhere</Button>
    </Card.Body>
    <Card.Footer className="text-muted">2 days ago</Card.Footer>
  </Card>
); */
