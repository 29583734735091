// ResendVerificationEmail.js
import React from "react";
import axios from "../../api/axios";


const RESEND_VERIFICATION_EMAIL_URL = "/recruiter/resend_verification_email/";

const ResendVerificationEmail = ({ onSuccess, onError }) => {
    const email = localStorage.getItem("email");
  const handleResendVerificationEmail = async () => {
    try {
      // Make a POST request to resend verification email
      const resendResponse = await axios.post(RESEND_VERIFICATION_EMAIL_URL, {
        email,
      });

      console.log("Resend Email API Response:", resendResponse.data);

      // Handle the response as needed
      // For example, you can show a success message and trigger a callback
      if (resendResponse.data.status === 200) {
        onSuccess && onSuccess();
      } else {
        onError && onError();
      }
    } catch (error) {
      console.log("Resend Email Error:", error);
      // Handle error as needed
      onError && onError();
    }
  };

  return (
    <div className="text-center">
      Can't find the email?
      <button className="btn btn-link" onClick={handleResendVerificationEmail}>
        We can resend it.
      </button>
    </div>
  );
};

export default ResendVerificationEmail;
