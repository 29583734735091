import img1 from "../images/review/testimonials.jpg";

const dataTestimonials = [
  {
    id: 1,
    text: "“Jobtex is allowing us to go through a large number of candidates with internal limited resources. We are able to do a first screening of candidates so much easier than if we had to meet everyone. We can truly identify and assess a talent pool more efficiently and have our talent ready to start in their new role faster.”",
    avt: img1,
    name: "Pete Jones",
    postion: "Head of Marketing Build",
  },
  {
    id: 2,
    text: "“Jobtex is allowing us to go through a large number of candidates with internal limited resources. We are able to do a first screening of candidates so much easier than if we had to meet everyone. We can truly identify and assess a talent pool more efficiently and have our talent ready to start in their new role faster.”",
    avt: img1,
    name: "Pete Jones",
    postion: "Head of Marketing Build",
  },
  {
    id: 3,
    text: "“Jobtex is allowing us to go through a large number of candidates with internal limited resources. We are able to do a first screening of candidates so much easier than if we had to meet everyone. We can truly identify and assess a talent pool more efficiently and have our talent ready to start in their new role faster.”",
    avt: img1,
    name: "Pete Jones",
    postion: "Head of Marketing Build",
  },
  {
    id: 4,
    text: "“Jobtex is allowing us to go through a large number of candidates with internal limited resources. We are able to do a first screening of candidates so much easier than if we had to meet everyone. We can truly identify and assess a talent pool more efficiently and have our talent ready to start in their new role faster.”",
    avt: img1,
    name: "Pete Jones",
    postion: "Head of Marketing Build",
  },
];

export default dataTestimonials;
