import React, { useState, useRef, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import { styled } from "@mui/system";
import { useDropzone } from "react-dropzone";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const RetrieveRecruiterDetails_URL = "/recruiter/recruiter_profile/";

function JobSeekerAddSkills() {
  /*  const GreenButton = styled(Button)({
    "&:hover": {
      backgroundColor: "#148160",
    },
  }); */
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const access = localStorage.getItem("access");

  const initialValues = {
    profile_id: "",
    organization_name: "",
    registration_number: "",
    logo: null,
    website: "",
    contact_number: "",
    entity_type: "",
    parent_org_name: "",
    num_employees: "",
    about: "",
    key_business_activities: "",
    industry_sector: "",
    org_address: "",
    parent_org_address: "",
    billing_address: "",
    primary_contact_name: "",
    primary_contact_email: "",
    primary_contact_tel: "",
    client_entity_type: "",
    client_org_name: "",
    client_num_employees: "",
    client_org_website: "",
    client_org_industry: "",
    client_org_address: "",
    client_primary_contact_email: "",
    client_primary_contact_phone: "",
    contract_copy_with_client: null,
    registration_number: "",
    photo_logo: null,
    website: "",
    contact_number: "",
    billing_address2: "",
  };

  const onSubmit = async (values) => {
    try {
      // Assuming you have an endpoint for updating data
      const response = await axios.patch(
        RetrieveRecruiterDetails_URL,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "multipart/form-data",
          },
        },

        {
          organization_name: values.organization_name,
          registration_number: values.registration_number,
          logo: values.logo,
          website: values.website,
          contact_number: values.contact_number,
          entity_type: values.entity_type,
          parent_org_name: values.parent_org_name,
          num_employees: values.num_employees,
          about: values.about,
          key_business_activities: values.key_business_activities,
          industry_sector: values.industry_sector,
          org_address: values.org_address,
          parent_org_address: values.parent_org_address,
          billing_address: values.billing_address,
          primary_contact_name: values.primary_contact_name,
          primary_contact_email: values.primary_contact_email,
          primary_contact_tel: values.primary_contact_tel,
          client_entity_type: values.client_entity_type,
          client_org_name: values.client_org_name,
          client_num_employees: values.client_num_employees,
          client_org_website: values.client_org_website,
          client_org_industry: values.client_org_industry,
          client_org_address: values.client_org_address,
          client_primary_contact_email: values.client_primary_contact_email,
          client_primary_contact_phone: values.client_primary_contact_phone,
          photo_logo: values.photo_logo,
          billing_address2: values.billing_address2,
        }
      );

      console.log(response.data);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const validationSchema = Yup.object().shape({
    // Define your form fields and their validation rules
    organization_name: Yup.string().required("Organization name is required"),
    registration_number: Yup.string().required(
      "Registration number is required"
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <button
        className="tf-btn-submit style-2"
        variant="primary"
        color="primary"
        onClick={handleOpen}
      >
        Add Skill
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="modal-title">Add Skill</DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <div className="ip p-2">
                      <label htmlFor="education"></label>
                      <select
                        id="education"
                        name="education"
                        value="" // Static value
                      >
                        <option value="" label="Select Skill" />

                        <option value="1" label="Option 1" />
                        <option value="2" label="Option 2" />
                        <option value="3" label="Option 3" />
                      </select>

                      {formik.touched.title && formik.errors.title && (
                        <div style={{ color: "red" }}>
                          {formik.errors.title}
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="ip p-2">
                      <label htmlFor="education"></label>
                      <select
                        id="education"
                        name="education"
                        value="" // Static value
                      >
                        <option value="" label="Select Experience" />

                        <option value="1" label="Option 1" />
                        <option value="2" label="Option 2" />
                        <option value="3" label="Option 3" />
                      </select>

                      {formik.touched.title && formik.errors.title && (
                        <div style={{ color: "red" }}>
                          {formik.errors.title}
                        </div>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <div className="ip p-2">
                      <button className="tf-btn-submit style-2">
                        Update Skill
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/*  <Button onClick={handleClose} variant="contained" color="primary">
              Submit
            </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default JobSeekerAddSkills;
