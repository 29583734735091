import img1 from '../images/pages/shop-1.jpg'
import img2 from '../images/pages/shop-2.jpg'
import img3 from '../images/pages/shop-3.jpg'
import img4 from '../images/pages/shop-4.jpg'
import img5 from '../images/pages/shop-5.jpg'
import img6 from '../images/pages/shop-6.jpg'
import img7 from '../images/pages/shop-7.jpg'
import img8 from '../images/pages/shop-8.jpg'
import img9 from '../images/pages/shop-9.jpg'



const dataProduct = [
    {
        id: 1,
        img1: img1,
        img2: img9,
        title: 'You Are A Badass',
        price: '$50.00',
        pricesale:'$44.00'
    },
    {
        id: 2,
        img1: img2,
        img2: img8,
        title: 'Atomic Habits',
        price: '',
        pricesale:'$44.00'
    },
    {
        id: 3,
        img1: img3,
        img2: img7,
        title: 'Thinking, Fast And Slow',
        price: '$50.00',
        pricesale:'$44.00'
    },
    {
        id: 4,
        img1: img4,
        img2: img6,
        title: 'The Four Agreements',
        price: '',
        pricesale:'$44.00'
    },
    {
        id: 5,
        img1: img5,
        img2: img4,
        title: 'The 7 Habits Of Highly',
        price: '',
        pricesale:'$44.00'
    },
    {
        id: 6,
        img1: img6,
        img2: img3,
        title: 'Best Self Be You, Only Better',
        price: '$50.00',
        pricesale:'$44.00'
    },
    {
        id: 7,
        img1: img7,
        img2: img2,
        title: 'The Subtle Art Of Not Giving A Fck',
        price: '',
        pricesale:'$44.00'
    },
    {
        id: 8,
        img1: img8,
        img2: img1,
        title: 'Girl Wash Your Face',
        price: '',
        pricesale:'$44.00'
    },
    {
        id: 9,
        img1: img9,
        img2: img5,
        title: 'Power',
        price: '$50.00',
        pricesale:'$44.00'
    },


]

export default dataProduct;