import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";



import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import { styled } from "@mui/system";
import { useDropzone } from "react-dropzone";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const RetrieveRecruiterDetails_URL = "/recruiter/recruiter_profile/";
const ResumeTable = () => {
  const access = localStorage.getItem("access");
  const initialValues = {
    profile_id: "",
    organization_name: "",
    registration_number: "",
    logo: null,
    website: "",
    contact_number: "",
    entity_type: "",
    parent_org_name: "",
    num_employees: "",
    about: "",
    key_business_activities: "",
    industry_sector: "",
    org_address: "",
    parent_org_address: "",
    billing_address: "",
    primary_contact_name: "",
    primary_contact_email: "",
    primary_contact_tel: "",
    client_entity_type: "",
    client_org_name: "",
    client_num_employees: "",
    client_org_website: "",
    client_org_industry: "",
    client_org_address: "",
    client_primary_contact_email: "",
    client_primary_contact_phone: "",
    contract_copy_with_client: null,
    registration_number: "",
    photo_logo: null,
    website: "",
    contact_number: "",
    billing_address2: "",
  };

  const onSubmit = async (values) => {
    try {
      // Assuming you have an endpoint for updating data
      const response = await axios.patch(
        RetrieveRecruiterDetails_URL,
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "multipart/form-data",
          },
        },

        {
          organization_name: values.organization_name,
          registration_number: values.registration_number,
          logo: values.logo,
          website: values.website,
          contact_number: values.contact_number,
          entity_type: values.entity_type,
          parent_org_name: values.parent_org_name,
          num_employees: values.num_employees,
          about: values.about,
          key_business_activities: values.key_business_activities,
          industry_sector: values.industry_sector,
          org_address: values.org_address,
          parent_org_address: values.parent_org_address,
          billing_address: values.billing_address,
          primary_contact_name: values.primary_contact_name,
          primary_contact_email: values.primary_contact_email,
          primary_contact_tel: values.primary_contact_tel,
          client_entity_type: values.client_entity_type,
          client_org_name: values.client_org_name,
          client_num_employees: values.client_num_employees,
          client_org_website: values.client_org_website,
          client_org_industry: values.client_org_industry,
          client_org_address: values.client_org_address,
          client_primary_contact_email: values.client_primary_contact_email,
          client_primary_contact_phone: values.client_primary_contact_phone,
          photo_logo: values.photo_logo,
          billing_address2: values.billing_address2,
        }
      );

      console.log(response.data);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const validationSchema = Yup.object().shape({
    // Define your form fields and their validation rules
    organization_name: Yup.string().required("Organization name is required"),
    registration_number: Yup.string().required(
      "Registration number is required"
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [data, setData] = useState([
    { id: 1, title: "RTT", name: "John Doe", age: 30 },
    { id: 2, title: "CSR", name: "Jane Smith", age: 25 },
    { id: 3, title: "OPO", name: "Bob Johnson", age: 40 },
  ]);

  const handleEdit = (id) => {
    // Add your edit logic here
    console.log("Edit item with ID:", id);
  };

  const handleDelete = (id) => {
    // Add your delete logic here
    setData(data.filter((item) => item.id !== id));
  };
  const GreenButton = styled(Button)({
    "&:hover": {
      backgroundColor: "#148160",
    },
  });
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>CV Title</TableCell>
            <TableCell>Default CV</TableCell>
            <TableCell>Added Date</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.age}</TableCell>
              <TableCell>
                {/* <IconButton onClick={() => handleEdit(row.id)}><EditIcon /></IconButton> */}
              {/*   <GreenButton
                  className="tf-btn-submit style-2 mt-4"
                  variant="primary"
                  color="primary"
                  onClick={() => handleEdit(row.id)}
                >
                  <EditIcon />
                </GreenButton> */}
                <button
                  className="tf-btn-submit style-2"
                  variant="primary"
                  color="primary"
                  onClick={handleOpen}
                >
                  <EditIcon />
                </button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-title"
                  aria-describedby="modal-description"
                  maxWidth="lg"
                  fullWidth
                >
                  <DialogTitle id="modal-title">Edit CV</DialogTitle>
                  <DialogContent dividers>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {(formik) => (
                        <form onSubmit={formik.handleSubmit}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <div className="ip p-2">
                                <label htmlFor="organization_name"></label>
                                <input
                                  type="text"
                                  placeholder="CV title"
                                  id="organization_name"
                                  name="organization_name"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.organization_name}
                                />
                                {formik.touched.organization_name &&
                                  formik.errors.organization_name && (
                                    <div>{formik.errors.organization_name}</div>
                                  )}
                              </div>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <div className="ip p-2">
                                <label htmlFor="logo"></label>

                                <input
                                  type="file"
                                  id="logo"
                                  name="logo"
                                  onChange={(event) => {
                                    formik.setFieldValue(
                                      "logo",
                                      event.currentTarget.files[0]
                                    );
                                    console.log("onChange", event);
                                  }}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.logo && formik.errors.logo && (
                                  <div>{formik.errors.logo}</div>
                                )}
                              </div>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <div className="ip p-2">
                                <FormControl>
                                  <FormLabel id="demo-row-radio-buttons-group-label">
                                    Is default?
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                  >
                                    <FormControlLabel
                                      value="yes"
                                      control={<Radio color="success" />}
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value="no"
                                      control={<Radio color="success" />}
                                      label="No"
                                    />
                                  </RadioGroup>
                                </FormControl>

                                {formik.touched.application_deadline &&
                                  formik.errors.application_deadline && (
                                    <div style={{ color: "red" }}>
                                      {formik.errors.application_deadline}
                                    </div>
                                  )}
                              </div>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <div className="ip p-2">
                                <button className="tf-btn-submit style-2">
                                 UPDATE CV
                                </button>
                              </div>
                            </Grid>
                          </Grid>
                        </form>
                      )}
                    </Formik>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                   
                  </DialogActions>
                </Dialog>
           
                <button
                  className="tf-btn-submit style-2"
                  variant="primary"
                  color="primary"
                  onClick={() => handleDelete(row.id)}
                >
                 <DeleteIcon />
                </button>
              
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResumeTable;
