import React, { useState } from "react";
import { useRef, useEffect } from "react";
import img from "../../assets/images/review/google.png";
import img2 from "../../assets/images/review/tweet.png";
import axios from "../../api/axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const LOGIN_URL = "/authentication/login/";

function SignIn() {
  const [showPass, setShowPass] = useState(false);
  const [username, setUsername] = useState("");
  const [Role, setRole] = useState("");
  const [user, setUser] = useState("");
  const [roles, setroles] = useState("");
  const [accesstoken, setAccesstoken] = useState("");
  //console.log(username)
  //console.log(accesstoken)

  const { setAuth } = useAuth();

  //console.log("setAuth",setAuth)
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  // const userRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  //const [errMsg, setErrMsg] = useState("");

  /*   useEffect(() => {
    userRef.current.focus();
  }, []); */

  useEffect(() => {
    setError("");
  }, [email, password]);

  useEffect(() => {
    setEmail("");
    setPassword("");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(LOGIN_URL, { email, password });
      //console.log('response',response.data['status'])
      let response_status = response.data["status"];
      if (response_status === 401) {
        setError(response.data["message"]);
      } else if (response_status === 400) {
        setError(response.data["message"]);
      } else if (response_status === 200) {
        //console.log(JSON.stringify(response));
        const refreshToken = response?.data?.refresh;
        const accesstoken = response?.data?.access;
        setAccesstoken(accesstoken);
        localStorage.setItem("access", accesstoken);
        const apiEmail = response?.data?.email;
        setEmail(apiEmail);
        localStorage.setItem("email", apiEmail);
        const apiUsername = response?.data?.username;
        setUsername(apiUsername);
        localStorage.setItem("username", apiUsername);
        localStorage.setItem("refreshToken", refreshToken);
        const roles = response?.data?.roles;
        setroles(roles);
        // console.log("roles",roles)
        const user = response?.data?.username;
        setUser(user);
        setAuth({ email, password, roles, user });
        const Role = response?.data?.roles;
        setRole(Role);

        /* const userRole = [  ];
localStorage.setItem("userRole", JSON.stringify(userRole)); */

        localStorage.setItem("roles", Role);
        //console.log("Role",Role)

        setEmail("");
        setPassword("");
        if (Role.includes("recruiter")) {
          navigate("/recruiterprofile2");
        } else if (Role.includes("job_seeker")) {
          navigate("/jobseeker/dashboard");
        } else {
          navigate(from, { replace: true });
        }
        //navigate(from, { replace: true });
        //console.log("Redirecting to:", from);
      } else {
        setError("An unexpected error occurred.");
      }
    } catch (error) {
      //console.log('error',error)
      // Handle error response
      if (error.response && error.response.status === 401) {
        setError("Invalid email or password.");
        //alert("vannu");
      } else if (error.response && error.response.status === 400) {
        setError("'password' missing");
      } else {
        // Handle other types of errors
        setError("An unexpected error occurred.");
      }
    }
  };

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <div className="wd-form-login">
            <h4>Log In</h4>
          {/*   {error && <div style={{ color: "red" }}>{error}</div>} */}

            <form onSubmit={handleSubmit}>
              <div className="ip">
                <label htmlFor="email">
                  Username or email address<span>*</span>
                </label>

                <input
                  type="text"
                  id="email"
                  // ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </div>
              <div className="ip">
                <label htmlFor="password">
                  Password<span>*</span>
                </label>
                <div className="inputs-group auth-pass-inputgroup">
                  <input
                    type={showPass ? "text" : "password"}
                    autoComplete="new-password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                  />
                  {/* <input
                    type={showPass ? "text" : "password"}
                    className="input-form password-input"
                    placeholder="Password"
                    id="password-input"
                    required
                  /> */}

                  <Link
                    className={`password-addon ${
                      showPass ? "icon-eye" : "icon-eye-off"
                    }`}
                    id="password-addon"
                    onClick={() => setShowPass(!showPass)}
                  />
                </div>
              </div>
              <div className="group-ant-choice">
                <div className="sub-ip">
                  <input type="checkbox" />
                  Remember me
                </div>
                <Link to="/password/reset" className="forgot">
                  Fogot password?
                </Link>
              </div>
              <p className="line-ip">
                <span>or sign up with</span>
              </p>
              <p
                    ref={errRef}
                    className={error ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {error}
                  </p>
              <button type="submit">Login</button>
              <Link to="#" className="btn-social">
                Continue with Facebook
              </Link>
              <Link to="#" className="btn-social">
                <img src={img} alt="images" /> Continue with Google
              </Link>
              <Link to="#" className="btn-social">
                <img src={img2} alt="images" /> Continue with Twitter
              </Link>

              <div className="sign-up">
                Not registered yet? <Link to="/createaccount">Sign Up</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
