import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import RangeOne from "../range-slider";
import RangeTwo from "../range-slider/RangleTwo";
import SelectLocation from "../dropdown";
import Dropdown from "react-dropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";
import Select from "react-select";

Sidebar.propTypes = {};
const JOBPOSTING_URL = "jobs/create_job/";
const STATICTITLE_URL = "/data/job_title_suggestions/";
const STATICLOCATION_URL = "/data/location/";
const STATICINDUSTRY_URL = "/data/industry/";
const STATICJOBFUNCTION_URL = "/data/job_function/";

function Sidebar(props) {
  const access = localStorage.getItem("access");

  const [titlename, setTitlename] = useState([]);
  const [showLocationInput, setShowLocationInput] = useState(true);
  const [getLocation, setGetLocation] = useState([]);
  const [getIndustry, setGetIndustry] = useState([]);
  const [getJobFunction, setGetJobFunction] = useState([]);

  const initialValues = {
    title: "",
    job_code: "",
    location_type: "",
    location: "",
    job_type: "",
    education: "",
    experience: "",
    description: "",
    requirements: "",
    responsibilities: "",
    benefits: "",
    skills: [],
    application_deadline: null,
    vacancies: "",
    min_salary: "",
    max_salary: "",
    company_industry: "",
    job_function: "",
    status: "draft",
  };
  const onSubmit = async (values) => {
    try {
      const response = await axios.post(
        JOBPOSTING_URL,

        {
          status: values.status,
          title: values.title,
          job_code: values.job_code,
          location_type: values.location_type,
          location: values.location,
          job_type: values.job_type,
          education: values.education,
          experience: values.experience,
          description: values.description,
          requirements: values.requirements,
          responsibilities: values.responsibilities,
          benefits: values.benefits,

          application_deadline: values.application_deadline,
          vacancies: values.vacancies,
          min_salary: values.min_salary,
          max_salary: values.max_salary,
        },
        {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("title is required"),
    location_type: Yup.string().required("Location Type is required"),
    min_salary: Yup.string().required("Min salary is required"),
    max_salary: Yup.string().required("Max salary Type is required"),
    description: Yup.string().required("Description is required"),
    requirements: Yup.string().required("Requirements is required"),
    responsibilities: Yup.string().required("Responsibilities is required"),
    application_deadline: Yup.date().required(
      "Application deadline is required"
    ),
    skills: Yup.array()
      .required("Skills is required")
      .min(1, "At least one skill is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const fetchTitle = async (query) => {
    try {
      const response = await axios.get(STATICTITLE_URL, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json",
        },
        params: { query },
      });

      const data = response.data;
      setTitlename(data);
      console.log();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTitle(formik.values.title);
  }, [formik.values.title]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(STATICLOCATION_URL, {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        });
        setGetLocation(response.data);
        console.log("Location Type:", formik.values.location_type);
      } catch (error) {
        console.error("Error fetching static data:", error);
      }
    };

    fetchData();
  }, [access, formik.values.location_type]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(STATICINDUSTRY_URL, {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        });
        setGetIndustry(response.data);
      } catch (error) {
        console.error("Error fetching static data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(STATICJOBFUNCTION_URL, {
          headers: {
            Authorization: `Bearer ${access}`,
            "Content-Type": "application/json",
          },
        });
        setGetJobFunction(response.data);
      } catch (error) {
        console.error("Error fetching static data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="widget-filter st2  style-scroll po-sticky">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit} className="wd-form-rating">
            <div className="group-form">
              <label className="title">job title</label>
              <div className="group-input search-ip">
                <button>
                  <i className="icon-search"></i>
                </button>
                <input
                  type="text"
                  list="titleList"
                  name="title"
                  id="title"
                  onChange={(e) => {
                    formik.handleChange(e);
                    fetchTitle(e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                />
                <datalist id="titleList">
                  {titlename.map((titleoption) => (
                    <option
                      key={titleoption.id}
                      value={titleoption.title}
                      id={titleoption.id}
                    />
                  ))}
                </datalist>
              </div>
            </div>
            <div className="group-form">
              <label className="title">Location</label>
              <div className="group-input has-icon">
                <i className="icon-map-pin"></i>
                <SelectLocation />
              </div>
            </div>
            <div className="group-form">
              <label className="title">Job function</label>
              <div className="group-input">
                <Field
                  as="select"
                  id="job_function"
                  name="job_function"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.job_function}
                >
                  <option value="" label="Select an option" />
                  {getJobFunction.map((JobFunctionOption) => (
                    <option
                      key={JobFunctionOption.id}
                      value={JobFunctionOption.name}
                      label={JobFunctionOption.name}
                    />
                  ))}
                </Field>
              </div>
            </div>
            <div className="group-form">
              <label className="title">Workplace</label>
              
              <div className="group-input">
                <Field
                  as="select"
                  id="location_type"
                  name="location_type"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setShowLocationInput(e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.location_type}
                >
                  <option value="" label="Select an option" />
                  {getLocation.map((locationOption) => (
                    <option
                      key={locationOption.id}
                      value={locationOption.name}
                      label={locationOption.name}
                    />
                  ))}
                </Field>
              </div>
            </div>
            <div className="group-form">
              <label className="title">Company industry</label>
              <div className="group-input">
                <Field
                  as="select"
                  id="company_industry"
                  name="company_industry"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company_industry}
                >
                  <option value="" label="Select an option" />
                  {getIndustry.map((IndustryOption) => (
                    <option
                      key={IndustryOption.id}
                      value={IndustryOption.name}
                      label={IndustryOption.name}
                    />
                  ))}
                </Field>
              </div>
            </div>
            <RangeTwo title="Salary:" />
            <RangeOne miles="Miles" title="Radius:" />

            <div className="group-form">
              <label className="title">Posted Anytime</label>
              <div className="group-input">
                <Dropdown className="react-dropdown select2" />
              </div>
            </div>
            <div className="group-form">
              <label className="title">All Seniority Levels</label>
              <div className="group-input">
                <Dropdown className="react-dropdown select2" />
              </div>
            </div>
            <div className="group-form">
              <label className="title">Company</label>
              <div className="group-input">
                <Dropdown className="react-dropdown select2" />
              </div>
            </div>
            <button type="submit">Find Jobs</button>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default Sidebar;
