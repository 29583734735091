import React, { useState } from "react";
import { useRef, useEffect } from "react";
import img from "../../assets/images/review/google.png";
import img2 from "../../assets/images/review/tweet.png";
import axios from "../../api/axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function ResetPassword() {
  const [email, setEmail] = useState("");

  const [error, setError] = useState(null);

  const errRef = useRef();
  useEffect(() => {
    setError("");
  }, [email]);

  useEffect(() => {
    setEmail("");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("hc", { email });
      //console.log('response',response.data['status'])
      let response_status = response.data["status"];
      if (response_status === 401) {
        setError(response.data["message"]);
      } else if (response_status === 400) {
        setError(response.data["message"]);
      } else if (response_status === 200) {
      } else {
        setError("An unexpected error occurred.");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Invalid email or password.");
      } else if (error.response && error.response.status === 400) {
        setError("'password' missing");
      } else {
        setError("An unexpected error occurred.");
      }
    }
  };

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <div className="wd-form-login">
            <h4>Reset Password</h4>

            <form onSubmit={handleSubmit}>
              <div className="ip">
                <label htmlFor="email">
                  Email Address<span>*</span>
                </label>

                <input
                  type="text"
                  id="email"
                  placeholder="Enter Your Register Email"
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </div>

              <p
                ref={errRef}
                className={error ? "errmsg" : "offscreen"}
                aria-live="assertive"
              >
                {error}
              </p>
              <button type="submit">Sent Password Resent Link</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
