import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";

/* import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"; */
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../api/axios";

const RetrieveRecruiterDetails_URL = "/recruiter/recruiter_profile/";
function RecruiterProfileView( /* { profile_id } */ ) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const access = localStorage.getItem("access");

  const initialValues = {
    profile_id: "",
    organization_name: "",
    registration_number: "",
    logo: null,
    website: "",
    contact_number: "",
    entity_type: "",
    parent_org_name: "",
    num_employees: "",
    about: "",
    key_business_activities: "",
    industry_sector: "",
    org_address: "",
    parent_org_address: "",
    billing_address: "",
    primary_contact_name: "",
    primary_contact_email: "",
    primary_contact_tel: "",
    client_entity_type: "",
    client_org_name: "",
    client_num_employees: "",
    client_org_website: "",
    client_org_industry: "",
    client_org_address: "",
    client_primary_contact_email: "",
    client_primary_contact_phone: "",
    contract_copy_with_client: null,
    registration_number: "",
    photo_logo: null,
    website: "",
    contact_number: "",
    billing_address2: "",
  };

  const onSubmit = async () => {
    try {
      // Assuming you have an endpoint for updating data
      const response = await axios.get(RetrieveRecruiterDetails_URL, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "multipart/form-data",
        },
      },
     /*  {
        profile_id: {profile_id},
       
      } */);

      console.log(response.data);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };


  useEffect(() => {
   
      onSubmit();
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  return (
    <>
      <div>
        <Button variant="primary" onClick={handleOpen}>
          <FontAwesomeIcon icon={faEye} />
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle id="modal-title">View Recruiter Profile</DialogTitle>
          <DialogContent dividers>
            <Formik
              initialValues={initialValues}
             
              onSubmit={onSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div className="form-rating-heading">
                        <h6>
                          Common fields of Agency and Organization recruiter
                          type
                        </h6>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="organization_name">
                          Organization Name:
                        </label>
                        <input
                          type="text"
                          // placeholder="EX: Boston University"
                          id="organization_name"
                          name="organization_name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.organization_name}
                        />
                        {formik.touched.organization_name &&
                          formik.errors.organization_name && (
                            <div>{formik.errors.organization_name}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="registration_number">
                          Register Number:
                        </label>
                        <input
                          type="text"
                          // placeholder="EX: Bachelor's"
                          id="registration_number"
                          name="registration_number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.registration_number}
                        />
                        {formik.touched.registration_number &&
                          formik.errors.registration_number && (
                            <div>{formik.errors.registration_number}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="website">Website:</label>
                        <input
                          type="text"
                          //placeholder="EX: Business"
                          id="website"
                          name="website"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.website}
                        />
                        {formik.touched.website && formik.errors.website && (
                          <div>{formik.errors.website}</div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="ip p-2">
                        <label htmlFor="logo">Logo:</label>

                        <input
                          type="file"
                          id="logo"
                          name="logo"
                          onChange={(event) => {
                            formik.setFieldValue(
                              "logo",
                              event.currentTarget.files[0]
                            );
                            console.log("onChange", event);
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.logo && formik.errors.logo && (
                          <div>{formik.errors.logo}</div>
                        )}
                      </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="contact_number">Contact Number:</label>
                        <input
                          type="text"
                          id="contact_number"
                          name="contact_number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact_number}
                        />
                        {formik.touched.contact_number &&
                          formik.errors.contact_number && (
                            <div>{formik.errors.contact_number}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="entity_type">Entity Type:</label>
                        <input
                          type="text"
                          id="entity_type"
                          name="entity_type"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.entity_type}
                        />
                        {formik.touched.entity_type &&
                          formik.errors.entity_type && (
                            <div>{formik.errors.entity_type}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="parent_org_name">
                          Parent Organization Name:
                        </label>
                        <input
                          type="text"
                          id="parent_org_name"
                          name="parent_org_name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.parent_org_name}
                        />
                        {formik.touched.parent_org_name &&
                          formik.errors.parent_org_name && (
                            <div>{formik.errors.parent_org_name}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="num_employees">Employees Number:</label>
                        <input
                          type="text"
                          id="num_employees"
                          name="num_employees"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.num_employees}
                        />
                        {formik.touched.num_employees &&
                          formik.errors.num_employees && (
                            <div>{formik.errors.num_employees}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="ip p-2">
                        <label htmlFor="about">About:</label>
                        <Field
                          as="textarea"
                          id="about"
                          name="about"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.about}
                          rows="4" // Set the number of rows for the textarea
                        />
                        {formik.touched.about && formik.errors.about && (
                          <div>{formik.errors.about}</div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="key_business_activities">
                          Key Business Activities:
                        </label>
                        <input
                          type="text"
                          id="key_business_activities"
                          name="key_business_activities"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.key_business_activities}
                        />
                        {formik.touched.num_employees &&
                          formik.errors.num_employees && (
                            <div>{formik.errors.num_employees}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="industry_sector">
                          Industry Sector:
                        </label>
                        <input
                          type="text"
                          id="industry_sector"
                          name="industry_sector"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.industry_sector}
                        />
                        {formik.touched.industry_sector &&
                          formik.errors.industry_sector && (
                            <div>{formik.errors.industry_sector}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="org_address">
                          Organization Address:
                        </label>
                        <input
                          type="text"
                          id="org_address"
                          name="org_address"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.org_address}
                        />
                        {formik.touched.org_address &&
                          formik.errors.org_address && (
                            <div>{formik.errors.org_address}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="parent_org_address">
                          Parent Organization Address:
                        </label>
                        <input
                          type="text"
                          id="parent_org_address"
                          name="parent_org_address"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.parent_org_address}
                        />
                        {formik.touched.parent_org_address &&
                          formik.errors.parent_org_address && (
                            <div>{formik.errors.parent_org_address}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="billing_address">
                          Billing Address:
                        </label>
                        <input
                          type="text"
                          id="billing_address"
                          name="billing_address"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.billing_address}
                        />
                        {formik.touched.billing_address &&
                          formik.errors.billing_address && (
                            <div>{formik.errors.billing_address}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="primary_contact_name">
                          Primary Contact Name:
                        </label>
                        <input
                          type="text"
                          id="primary_contact_name"
                          name="primary_contact_name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.primary_contact_name}
                        />
                        {formik.touched.primary_contact_name &&
                          formik.errors.primary_contact_name && (
                            <div>{formik.errors.primary_contact_name}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="primary_contact_email">
                          Primary Contact Email:
                        </label>
                        <input
                          type="text"
                          id="primary_contact_email"
                          name="primary_contact_email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.primary_contact_email}
                        />
                        {formik.touched.primary_contact_email &&
                          formik.errors.primary_contact_email && (
                            <div>{formik.errors.primary_contact_email}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="primary_contact_tel">
                          Primary Contact Telephone:
                        </label>
                        <input
                          type="text"
                          id="primary_contact_tel"
                          name="primary_contact_tel"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.primary_contact_tel}
                        />
                        {formik.touched.primary_contact_tel &&
                          formik.errors.primary_contact_tel && (
                            <div>{formik.errors.primary_contact_tel}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="form-rating-heading">
                        <h6>
                          Common fields of Agency and Individual Recruiter type
                        </h6>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="client_entity_type">
                          Client Entry Type:
                        </label>
                        <input
                          type="text"
                          id="client_entity_type"
                          name="client_entity_type"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.client_entity_type}
                        />
                        {formik.touched.client_entity_type &&
                          formik.errors.client_entity_type && (
                            <div>{formik.errors.client_entity_type}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="client_org_name">
                          Client Organization Name:
                        </label>
                        <input
                          type="text"
                          id="client_org_name"
                          name="client_org_name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.client_org_name}
                        />
                        {formik.touched.client_org_name &&
                          formik.errors.client_org_name && (
                            <div>{formik.errors.client_org_name}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="client_num_employees">
                          Client Number Employees:
                        </label>
                        <input
                          type="text"
                          id="client_num_employees"
                          name="client_num_employees"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.client_num_employees}
                        />
                        {formik.touched.client_num_employees &&
                          formik.errors.client_num_employees && (
                            <div>{formik.errors.client_num_employees}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="client_org_website">
                          Client Organization Website:
                        </label>
                        <input
                          type="text"
                          id="client_org_website"
                          name="client_org_website"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.client_org_website}
                        />
                        {formik.touched.client_org_website &&
                          formik.errors.client_org_website && (
                            <div>{formik.errors.client_org_website}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="client_org_industry">
                          Client Organization Industry:
                        </label>
                        <input
                          type="text"
                          id="client_org_industry"
                          name="client_org_industry"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.client_org_industry}
                        />
                        {formik.touched.client_org_industry &&
                          formik.errors.client_org_industry && (
                            <div>{formik.errors.client_org_industry}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="client_org_address">
                          Client Organization Address:
                        </label>
                        <input
                          type="text"
                          id="client_org_address"
                          name="client_org_address"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.client_org_address}
                        />
                        {formik.touched.client_org_address &&
                          formik.errors.client_org_address && (
                            <div>{formik.errors.client_org_address}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="client_primary_contact_email">
                          Client Primary Contact Email:
                        </label>
                        <input
                          type="text"
                          id="client_primary_contact_email"
                          name="client_primary_contact_email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.client_primary_contact_email}
                        />
                        {formik.touched.client_primary_contact_email &&
                          formik.errors.client_primary_contact_email && (
                            <div>
                              {formik.errors.client_primary_contact_email}
                            </div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="client_primary_contact_phone">
                          Client Primary Contact Phone:
                        </label>
                        <input
                          type="text"
                          id="client_primary_contact_phone"
                          name="client_primary_contact_phone"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.client_primary_contact_phone}
                        />
                        {formik.touched.client_primary_contact_phone &&
                          formik.errors.client_primary_contact_phone && (
                            <div>
                              {formik.errors.client_primary_contact_phone}
                            </div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="ip p-2">
                        <label htmlFor="contract_copy_with_client">
                          Contract Copy With Client:
                        </label>
                        <input
                          type="file"
                          id="contract_copy_with_client"
                          name="contract_copy_with_client"
                          onChange={(event) => {
                            formik.setFieldValue(
                              "contract_copy_with_client",
                              event.currentTarget.files[0]
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.contract_copy_with_client &&
                          formik.errors.contract_copy_with_client && (
                            <div>{formik.errors.contract_copy_with_client}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="form-rating-heading">
                        <h6>
                          Common fields of Agency and Organization recruiter
                          type
                        </h6>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="organization_name">
                          Organization Name:
                        </label>
                        <input
                          type="text"
                          id="organization_name"
                          name="organization_name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.organization_name}
                        />
                        {formik.touched.organization_name &&
                          formik.errors.organization_name && (
                            <div>{formik.errors.organization_name}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="registration_number">
                          Registration Number:
                        </label>
                        <input
                          type="text"
                          id="registration_number"
                          name="registration_number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.registration_number}
                        />
                        {formik.touched.registration_number &&
                          formik.errors.registration_number && (
                            <div>{formik.errors.registration_number}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="ip p-2">
                        <label htmlFor="photo_logo">Photo Logo:</label>

                        <input
                          type="file"
                          id="photo_logo"
                          name="photo_logo"
                          onChange={(event) => {
                            formik.setFieldValue(
                              "photo_logo",
                              event.currentTarget.files[0]
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.photo_logo &&
                          formik.errors.photo_logo && (
                            <div>{formik.errors.photo_logo}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="website">Website:</label>
                        <input
                          type="text"
                          id="website"
                          name="website"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.website}
                        />
                        {formik.touched.website && formik.errors.website && (
                          <div>{formik.errors.website}</div>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="contact_number">Contact Number:</label>
                        <input
                          type="text"
                          id="contact_number"
                          name="contact_number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact_number}
                        />
                        {formik.touched.contact_number &&
                          formik.errors.contact_number && (
                            <div>{formik.errors.contact_number}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="ip p-2">
                        <label htmlFor="billing_address">Billing Address</label>
                        <input
                          type="text"
                          id="billing_address"
                          name="billing_address"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.billing_address}
                        />
                        {formik.touched.billing_address &&
                          formik.errors.billing_address && (
                            <div>{formik.errors.billing_address}</div>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className="ip p-2">
                        <button className="tf-btn-submit style-2">
                          View
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            {/*  <Button onClick={handleClose} variant="contained" color="primary">
              Submit
            </Button> */}
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default RecruiterProfileView;
