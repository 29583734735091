import React, { useState, useRef } from "react";
import { useEffect } from "react";

import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../../api/axios";
import { Link, useNavigate, useLocation } from "react-router-dom";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

//string
/* const PWD_REGEX_STRING = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,}$";
const PWD_REGEX = new RegExp(PWD_REGEX_STRING); */
//const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
const REGISTER_URL = "/authentication/register/";
const RECRUITER_URL = "/recruiter/registration/";

function SignUp() {
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [showPass3, setShowPass3] = useState(false);
  const [showPass4, setShowPass4] = useState(false);

  const userRef = useRef();
  const errRef = useRef();

  const [username, setUserName] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [successrecruiter, setSuccessrecruiter] = useState(false);
  const [jobseekerError, setjobseekerError] = useState(null);
  const [recruiterError, setRecruiterError] = useState(null);

  //recruiter
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [work_email, setWorkEmail] = useState("");
  const [recpassword, setrecPassword] = useState("");
  const [recruiter_type, setRecruiterType] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  useEffect(() => {
    setjobseekerError("");
  }, [username, email]);

  useEffect(() => {
    setRecruiterError("");
  }, [work_email, first_name, last_name]);

  useEffect(() => {
    setSuccess("");
  }, [username, email]);

  useEffect(() => {
    setSuccessrecruiter("");
  }, [work_email, first_name, last_name]);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };
  const handleRecPasswordChange = (e) => {
    setrecPassword(e.target.value);
  };

  const handleWorkEmailChange = (e) => {
    const inputEmail = e.target.value;

    // Email validation regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the entered email matches the validation regex
    const isValidEmail = emailRegex.test(inputEmail);

    // Set the email and update the validation state
    setWorkEmail(inputEmail);
    setValidEmail(isValidEmail);
  };

  const handleRecruiterTypeChange = (e) => {
    setRecruiterType(e.target.value);
  };

  /*  useEffect(() => {
    userRef.current.focus();
  }, []); */

  useEffect(() => {
    setValidName(USER_REGEX.test(username));
  }, [username]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(password));
    setValidMatch(password === matchPwd);
  }, [password, matchPwd]);

  useEffect(() => {
    setErrMsg("");
  }, [username, password, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = USER_REGEX.test(username);
    const v2 = PWD_REGEX.test(password);
    if (!v1 || !v2) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const response = await axios.post(REGISTER_URL, {
        username,
        email,
        password: matchPwd,
      });

      let response_status = response.data["status"];
      if (response_status === 400) {
        setjobseekerError(response.data.error.email[0]);
      } else if (response_status === 500) {
        setjobseekerError(response.data["message"]);
      } else if (response_status === 201) {
        //console.log(JSON.stringify(response))
       
        setSuccess(response.data["message"]);
        //clear state and controlled inputs
        setUserName("");
        setPassword("");
        setMatchPwd("");
        setEmail("");
        setjobseekerError("");
        //navigate("/login");
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 401) {
        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed");
      }
      errRef.current.focus();
    }
  };

  const handleSubmitRecruiter = async (event) => {
    event.preventDefault();

    try {
      // Replace the URL with your actual API endpoint

      // Sending a POST request with the form data
      const response = await axios.post(RECRUITER_URL, {
        first_name,
        last_name,
        work_email,
        recruiter_type,
        password: recpassword,
      });

      let recresponse_status = response.data["status"];
      if (recresponse_status === 400) {
        setRecruiterError(response.data.error.email[0]);
      } else if (recresponse_status === 201) {
        setSuccessrecruiter(response.data["message"]);
        //navigate("/login");

      }
    } catch (err) {
      setErrMsg("An unexpected error occurred.");
    }
  };

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <Tabs className="wd-form-login tf-tab">
            <h4>Create Link free account</h4>

            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <TabList className="menu-tab">
              <Tab className="ct-tab">Candidate</Tab>
              <Tab className="ct-tab">Recruiter</Tab>
            </TabList>
            <div className="content-tab">
              <TabPanel className="inner animation-tab">
                <form onSubmit={handleSubmit}>
                  {/* <div className="ip">
                    <label>
                      Username or email address<span>*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue="Tony Nguyen"
                      placeholder="Name"
                    />
                  </div> */}
                  <div className="ip">
                    <label htmlFor="username">
                      Username:
                      <FontAwesomeIcon
                        icon={faCheck}
                        className={validName ? "valid" : "hide"}
                      />
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={validName || !username ? "hide" : "invalid"}
                      />
                    </label>
                    <input
                      type="text"
                      id="username"
                      ref={userRef}
                      autoComplete="off"
                      onChange={(e) => setUserName(e.target.value)}
                      value={username}
                      required
                      aria-invalid={validName ? "false" : "true"}
                      aria-describedby="uidnote"
                      onFocus={() => setUserFocus(true)}
                      onBlur={() => setUserFocus(false)}
                    />
                    <p
                      id="uidnote"
                      className={
                        userFocus && username && !validName
                          ? "instructions"
                          : "offscreen"
                      }
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                      4 to 24 characters.
                      <br />
                      Must begin with a letter.
                      <br />
                      Letters, numbers, underscores, hyphens allowed.
                    </p>
                  </div>

                  <div className="ip">
                    <label htmlFor="email">
                      Email:
                      {/*          <FontAwesomeIcon
                        icon={faCheck}
                        className={validName ? "valid" : "hide"}
                      />
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={validName || !user ? "hide" : "invalid"}
                      /> */}
                    </label>
                    <input
                      type="email"
                      id="email"
                      //ref={userRef}
                      autoComplete="off"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      /*    required
                      aria-invalid={validName ? "false" : "true"}
                      aria-describedby="uidnote"
                      onFocus={() => setUserFocus(true)}
                      onBlur={() => setUserFocus(false)} */
                    />
                    {/*  <p
                      id="uidnote"
                      className={
                        userFocus && user && !validName
                          ? "instructions"
                          : "offscreen"
                      }
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                      4 to 24 characters.
                      <br />
                      Must begin with a letter.
                      <br />
                      Letters, numbers, underscores, hyphens allowed.
                    </p> */}
                  </div>

                  <div className="ip">
                    <label htmlFor="password">
                      Password<span>*</span>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className={validPwd ? "valid" : "hide"}
                      />
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={validPwd || !password ? "hide" : "invalid"}
                      />
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      {/*    <input
                        type={`${showPass ? "text" : "password"}`}
                        className="input-form password-input"
                        defaultValue="userabcdefg123"
                        placeholder="Password"
                        required
                      /> */}
                      <input
                        id="password"
                        type={`${showPass ? "text" : "password"}`}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required
                        autoComplete="new-password"
                        aria-invalid={validPwd ? "false" : "true"}
                        aria-describedby="pwdnote"
                        onFocus={() => setPwdFocus(true)}
                        onBlur={() => setPwdFocus(false)}
                      />
                      <p
                        id="pwdnote"
                        className={
                          pwdFocus && !validPwd ? "instructions" : "offscreen"
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                        8 to 24 characters.
                        <br />
                        Must include uppercase and lowercase letters, a number
                        and a special character.
                        <br />
                        Allowed special characters:{" "}
                        <span aria-label="exclamation mark">!</span>{" "}
                        <span aria-label="at symbol">@</span>{" "}
                        <span aria-label="hashtag">#</span>{" "}
                        <span aria-label="dollar sign">$</span>{" "}
                        <span aria-label="percent">%</span>
                      </p>

                      <Link
                        className={`password-addon ${
                          showPass ? "icon-eye" : "icon-eye-off"
                        }`}
                        onClick={() => setShowPass(!showPass)}
                      />
                    </div>
                  </div>
                  <div className="ip">
                    <label htmlFor="confirm_pwd">
                      Confirm Password<span>*</span>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className={validMatch && matchPwd ? "valid" : "hide"}
                      />
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={validMatch || !matchPwd ? "hide" : "invalid"}
                      />
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      {/*      <input
                        type={`${showPass2 ? "text" : "password"}`}
                        className="input-form password-input"
                        defaultValue="userabcdefg123"
                        placeholder="Password"
                        required
                      />  */}
                      <input
                        type={`${showPass2 ? "text" : "password"}`}
                        id="confirm_pwd"
                        onChange={(e) => setMatchPwd(e.target.value)}
                        value={matchPwd}
                        required
                        aria-invalid={validMatch ? "false" : "true"}
                        aria-describedby="confirmnote"
                        onFocus={() => setMatchFocus(true)}
                        onBlur={() => setMatchFocus(false)}
                      />
                      <p
                        id="confirmnote"
                        className={
                          matchFocus && !validMatch
                            ? "instructions"
                            : "offscreen"
                        }
                      >
                        <FontAwesomeIcon icon={faInfoCircle} />
                        Must match the first password input field.
                      </p>
                      <Link
                        className={`password-addon ${
                          showPass2 ? "icon-eye" : "icon-eye-off"
                        }`}
                        onClick={() => setShowPass2(!showPass2)}
                      />
                    </div>
                  </div>
                  <p
                    ref={errRef}
                    className={jobseekerError ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {jobseekerError}
                  </p>
                  <p
                    ref={errRef}
                    className={success ? "successmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {success}
                  </p>
                  <button
                    disabled={
                      !validName || !validPwd || !validMatch ? true : false
                    }
                  >
                    Register
                  </button>
                  <div className="sign-up">
                    Already have an account?
                    <Link to="/login">&nbsp;Login Here</Link>
                  </div>
                </form>
              </TabPanel>
              <TabPanel className="inner animation-tab">
                <form onSubmit={handleSubmitRecruiter}>
                  <div className="ip">
                    <label htmlFor="first_name">
                      Firstname:<span>*</span>
                    </label>
                    <input
                      type="text"
                      id="first_name"
                      autoComplete="off"
                      onChange={handleFirstNameChange}
                      value={first_name}
                      placeholder="Firstname"
                      required
                    />
                  </div>
                  <div className="ip">
                    <label htmlFor="last_name">
                      Lastname:
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="last_name"
                      autoComplete="off"
                      onChange={handleLastNameChange}
                      value={last_name}
                      placeholder="Lastname"
                      required
                    />
                  </div>
                  <div className="ip">
                    <label htmlFor="work_email">
                      Work Email:
                      <span>*</span>
                    </label>
                    <input
                      type="email"
                      id="work_email"
                      autoComplete="off"
                      onChange={handleWorkEmailChange}
                      value={work_email}
                      aria-invalid={validEmail ? "false" : "true"}
                      placeholder="email"
                      required
                    />
                  </div>

                  <div className="ip">
                    <label
                      htmlFor="recruiter_type
"
                    >
                      Recruiter Type:
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="recruiter_type
                          "
                      autoComplete="off"
                      onChange={handleRecruiterTypeChange}
                      value={recruiter_type}
                      placeholder="(organization,agency,individual)"
                      required
                    />
                  </div>
                  <div className="ip">
                    <label htmlFor="recpassword">
                      Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={`${showPass3 ? "text" : "password"}`}
                        id="recpassword"
                        value={recpassword}
                        onChange={handleRecPasswordChange}
                        className="input-form password-input"
                        autoComplete="off"
                        placeholder="Password"
                        required
                      />
                      <Link
                        className={`password-addon ${
                          showPass3 ? "icon-eye" : "icon-eye-off"
                        }`}
                        onClick={() => setShowPass3(!showPass3)}
                      />
                    </div>
                  </div>
                  {/* <div className="ip">
                    <label>
                      Confirm Password<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                        type={`${showPass4 ? "text" : "password"}`}
                       
                        className="input-form password-input"
                        defaultValue="userabcdefg123"
                        placeholder="Password"
                        required
                      />
                      <Link
                        className={`password-addon ${
                          showPass4 ? "icon-eye" : "icon-eye-off"
                        }`}
                        onClick={() => setShowPass4(!showPass4)}
                      />
                    </div>
                  </div> */}
                  {/*  <div className="group-ant-choice st">
                    <div className="sub-ip">
                      <input type="checkbox" />I agree to the
                      <Link to="/termsofuse"> Terms of User</Link>
                    </div>
                  </div> */}
                  <p
                    ref={errRef}
                    className={recruiterError ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {recruiterError}
                  </p>
                  <p
                    ref={errRef}
                    className={successrecruiter ? "successmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {successrecruiter}
                  </p>
                  <button>Register</button>
                  <div className="sign-up">
                    Already have an account?
                    <Link to="/login">&nbsp;Login Here</Link>
                  </div>
                </form>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
}

export default SignUp;
